//@import "node_modules/bootstrap/scss/bootstrap.scss";
//@import "node_modules/bootstrap-icons/font/bootstrap-icons.scss";

@import '_fonts';
@import '_variables';

@import 'home';
@import 'post';
@import '404';
@import 'footer';
/*@import 'general';


@import 'pages/page';
@import 'pages/post';*/
