body {
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background-color: $primary-background-color;
	color: $primary-foreground-color;
	font-family: $primary-font, serif;
	font-size: 1.1rem;
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar { display: none; }

.title-container {
	height: 100vh;
	width: 100%;
	text-align: center;

	.centered-title {
		display: flex;
  		justify-content: center;
  		align-items: center;
		height: 100%;
		font-size: 36px;
	}
}

.title-container-small {
	width: 100%;
	text-align: center;

	.centered-title {
		text-decoration: none;
		color: $primary-foreground-color;
		display: flex;
  		justify-content: center;
  		align-items: center;
		height: 100%;
		font-size: 36px;
	}
}
.post-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	

	.post {
		width: 80%;
		text-align: left;

		a {
			text-decoration: none;
			color: $primary-foreground-color;
		}
	}
}