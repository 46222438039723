.post-content {
	display: flex;
	flex-direction: column;
	align-items: center;

	.content {
		width: calc(100% / 1.41);
		max-width: 800px;
		min-height: 100vh;
		
		p {
			text-align: justify;
			text-indent: 1em;
			line-height: 1.3em;
			margin: .2em;
		}
	}
}

@media only screen and (max-width: 600px) {
	.post-content {
		.content {
			width: 90%;
		}
	}
}